<template>
  <div class="container">
    <div class="storyInfo">
      <img class="pic" :src="storyInfo.pic" />
      <div class="article">
        <div class="content" v-html="language === 'cn' ? storyInfo.cnContent : storyInfo.enContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
export default {
  name: 'storyInfo',
  components: {},
  computed: {
    language() {
      return this.$store.state.language
    }
  },
  data() {
    return {
      storyInfo: config.storyInfo
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.storyInfo {
  .pic {
    width: 100%;
  }
  .article {
    width: 950px;
    margin: 0 auto;
    .title {
      font-size: 30px;
      line-height: 30px;
      padding: 50px 0;
      text-align: center;
    }
    :deep(.content) {
      font-size: 15px;
      line-height: 26px;
      white-space: pre-wrap;
      text-align: justify;
    }
  }
}
</style>
